
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
  },

  emits : ["displayReservation"],

  setup() {
    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
      lHisto : [] as any, 
      reloadHisto : 1,
      histo: false,
      initiallHisto: [] as any,
      titre: "",
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Société", key: "uti_c_societe", sortable: false,},
      { name: "Date de réservation", key: "his_d_creation", sortable: false,},
      { name: "Réservation", key: "his_c_json_histo", sortable: false,},
      { name: "", key: "uti_n_seq", sortable: false,},
    ]);

    const tableHeader2 = ref([
    { name: "Référence", key: "ref", sortable: false,},
    { name: "Quantité", key: "qte", sortable: false,},
    { name: "", key: "id", sortable: false,},

  ]);

    const tableFilters = ref([

      { name: "utilisateur", key: "uti_c_prenom", label: "uti_c_prenom", },
      { name: "AM", key: "prenom_am", label: "prenom_am", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Société": "uti_c_societe",
      "AM Still": "prenom_am",
      "Reservation": "his_c_json_histo",
      "Date": "his_d_creation",
    });

    onMounted(async () => { 

      let reponse = await getAxios("/getAllUserReserv");
      state.myList = reponse.results;
      console.log(state.myList);
      

      state.initialMyList = reponse.results;
      state.zUser = state.myList[0];

      state.loaderEnabled = false;
      
    });

    const affHisto = async (data) => {
      let myListHisto = await getAxios("/getAllUserReservDetails/" + data.his_n_seq);
      state.initiallHisto = myListHisto.results;
      state.lHisto = JSON.parse(state.initiallHisto[0].his_c_json_histo);
      state.reloadHisto += 1;
      state.histo = true;
      state.titre = t("Reservation faite par") + " " + state.initiallHisto[0].uti_c_prenom + " " + state.initiallHisto[0].uti_c_nom;
  }

  const titleDialog =() => {
    return state.titre;
  }

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      affHisto,
      tableHeader2,
      titleDialog
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
