
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";
import router from "@/router";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
   Datatable,
   SearchAndFilter,
  },

  setup() {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Société", key: "uti_c_societe", sortable: false,},
      { name: "Références", key: "hse_c_search", sortable: false,},
      { name: "Recherchées le", key: "hse_d_creation", sortable: false,},
      { name: "", key: "uti_n_seq", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "utilisateur", key: "uti_c_prenom", label: "uti_c_prenom", },
      { name: "AM", key: "prenom_am", label: "prenom_am", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const followSearch = (search) => {
      router.push("/board/overview/" + search)
    }

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Société": "uti_c_societe",
      "AM Still": "prenom_am",
      "Recherche": "hse_c_search",
      "Date": "hse_d_creation",
    });

    onMounted(async () => { 


      let reponse = await getAxios("/getAllUserHisto");
      state.myList = reponse.results;

      
      state.initialMyList = reponse.results;
      state.zUser = state.myList[0];

      state.loaderEnabled = false;
      

    });


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      followSearch,

    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
