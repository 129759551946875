<template>
  <transition name="fade">
      <el-row :gutter="10" class="mt-10" style="width: 100%">
        <el-col :span="24">

          <div class="card" v-if="!state.loaderEnabled" style="width: 100%;">
            <div class="card-header border-0 pt-6">
              <div class="card-title" style="width:100%;">
                <h2>{{$t("Recherches effectuées par les utilisateurs")}}</h2>
              </div>
              <SearchAndFilter
                :listInitial="state.initialMyList"
                :filters="tableFilters"
                @returnSearch="state.myList = $event"
                @returnLoadingDatatable="state.loadingDatatable = $event"
                ExcelFilename="StillOnStockHistorique"
                :ExcelFormat="ExcelFormat"
                ></SearchAndFilter>
            </div>
            <div class="card-body pt-0">
              <div
                v-if="state.myList.length == 0"
                class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
              >
                <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                  <inline-svg src="media/icons/duotune/general/gen048.svg" />
                </span>
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
                  <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
                </div>
              </div>
             <Datatable
                      v-if="state.myList && state.myList.length > 0"
                      :table-data="state.myList"
                      :table-header="tableHeader"
                      :enable-items-per-page-dropdown="true"
                      :key="state.loadingDatatable"
                      :empty-table-text="$t('Aucune recherche effectuée')"
                      class="table-very-small-td"
                    >
                    <template v-slot:cell-uti_c_prenom="{ row: data }"> 
                        {{ data.uti_c_prenom }} {{ data.uti_c_nom }}
                    </template>
                    <template v-slot:cell-uti_c_mail="{ row: data }">
                      <div class="fs-7 text-gray-500">
                        <div>{{ data.uti_c_phone }}</div>
                        <div class="text-blue">{{ data.uti_c_mail }}</div>
                      </div>
                    </template>
                    <template v-slot:cell-uti_c_societe="{ row: data }">
                      {{ data.uti_c_societe }}<br />
                      <span class=" text-gray-400" v-if="data.prenom_am">{{$t("AM Still':")}} {{ data.prenom_am }}</span>
                    </template>
                    <template v-slot:cell-hse_c_search="{ row: data }"> 
                      <a @click="followSearch (data.hse_c_search)" class="search-link">
                        {{ data.hse_c_search }}
                      </a>
                    </template>
                    <template v-slot:cell-hse_d_creation="{ row: data }"> 
                        {{ data.hse_d_creation }}
                    </template>
              
              </Datatable>

            </div>
          </div>

        </el-col>
      </el-row>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import SearchAndFilter from "@/views/still/search_and_filters_old.vue";
import router from "@/router";

import * as moment from "moment";

import mAxiosApi from "@/api";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
   Datatable,
   SearchAndFilter,
  },

  setup() {
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      zUser: {},
    });
    const tableHeader = ref([
      { name: "Prénom Nom", key: "uti_c_prenom", sortable: false,},
      { name: "Coordonnées", key: "uti_c_mail", sortable: false,},
      { name: "Société", key: "uti_c_societe", sortable: false,},
      { name: "Références", key: "hse_c_search", sortable: false,},
      { name: "Recherchées le", key: "hse_d_creation", sortable: false,},
      { name: "", key: "uti_n_seq", sortable: false,},
    ]);

    const tableFilters = ref([
      { name: "utilisateur", key: "uti_c_prenom", label: "uti_c_prenom", },
      { name: "AM", key: "prenom_am", label: "prenom_am", },
      { name: "Société", key: "uti_c_societe", label: "uti_c_societe", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang + " HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const followSearch = (search) => {
      router.push("/board/overview/" + search)
    }

    const ExcelFormat = ref({
      "Prénom": "uti_c_prenom",
      "Nom": "uti_c_nom",
      "Tel": "uti_c_phone",
      "Mail": "uti_c_mail",
      "Creation": "uti_d_import",
      "Société": "uti_c_societe",
      "AM Still": "prenom_am",
      "Recherche": "hse_c_search",
      "Date": "hse_d_creation",
    });

    onMounted(async () => { 


      let reponse = await getAxios("/getAllUserHisto");
      state.myList = reponse.results;

      
      state.initialMyList = reponse.results;
      state.zUser = state.myList[0];

      state.loaderEnabled = false;
      

    });


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      ExcelFormat,
      followSearch,

    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>

<style>
  .search-link{
    cursor: pointer;
  }
</style>