<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-xl-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
        <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary':state.paneBord==1,  'btn-custom-white':state.paneBord!=1 }"
              @click="state.paneBord=1"
              style=''
            >
              {{$t("Gestion des utilisateurs")}}
            </a>
          </li>
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary':state.paneBord==2,  'btn-custom-white':state.paneBord!=2 }"
              @click="state.paneBord=2"
              style=''
            >
              {{$t("Historique des recherches")}}
            </a>
          </li>
          <li class="nav-item" style="float: left;">
            <a
              class="btn text-active-light me-6"
              :class="{ 'btn-primary':state.paneBord==3,  'btn-custom-white':state.paneBord!=3 }"
              @click="state.paneBord=3"
              style=''
            >
              {{$t("Historique des reservations")}}
            </a>
          </li>
          <li class="nav-item" style="float: right;">
            <a
              class="btn btn-custom-red text-active-light me-6 ml-6"
              style='margin-right: 10px !important;'
              v-if="state.paneBord==1"
              @click="newUser"
            >
              {{$t("Créer un utilisateur")}}
            </a>
          </li>
        </ul>
      </div>

      <Users v-if="state.paneBord==1" @displayUser="editUser($event)"  @displayUserHisto="affHisto($event)" :key="state.count" />
      <Histo v-if="state.paneBord==2" :key="state.count" />
      <Reserv v-if="state.paneBord==3" :key="state.count" />

    </div>
  </div>

 <el-dialog v-model="state.drawer" :title="`${ modalUser.uti_n_seq == 0 ? $t('Création') : $t('Modification') } ${$t('d\'un utilisateur')}`" width="800">
   <el-row :gutter="10" style="width: 100%">
        <el-col :span="16">
          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Identité")}}</strong><span class="text-danger">*</span></span></div>

              <div class="col"> 
                <el-input v-model="modalUser.uti_c_prenom" :placeholder="$t('Spécifier le Prénom')" class="m-2" style='float: left; width: calc(100%);' /> 
                <el-input v-model="modalUser.uti_c_nom" :placeholder="$t('Spécifier le nom')" class="m-2" style='float: left; width: calc(100%);' /> 
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Société")}}</strong></span></div>
              <div class="col"> <el-input v-model="modalUser.uti_c_societe" :placeholder="$t('Spécifier la société')" class="m-2" /> </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Coordonnées")}}</strong></span></div>
              <div class="col"> <el-input v-model="modalUser.uti_c_phone" :placeholder="$t('Spécifier le Téléphone')" class="m-2" /> 
              <el-input v-model="modalUser.uti_c_mail" :placeholder="$t('Spécifier le Mail *')" class="m-2" />
              </div>
          </div>

          <div class="row row-user">
              <div class="col-sm-4 col-xxl-3 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Paramètrage")}}</strong></span></div>
              <div class="col"> 
                <el-checkbox v-model="modalUser.uti_x_adm" class="m-2" :label="$t('Utilisateur adminstrateur')" />
                <el-checkbox v-model="modalUser.uti_x_stillnetwork" class="m-2" :label="$t('Utilisateur Stillnetwork')" />
                <el-checkbox v-model="modalUser.uti_x_bloque" class="m-2" :label="$t('Compte désactivé')" />
              </div>
          </div>
        </el-col>
        <el-col :span="8" style='border-left: 1px solid #e3e6f0;'>
          <div class="p-5"><span style="font-size: 15px;"><strong>{{$t("Lien vers un AM Stillnetwork")}}</strong></span></div>
            
            <el-select filterable clearable v-model="modalUser.uti_knum_user_still" class="m-2 new-bord prod-user" style='width: 100%; margin-top: 10px; max-height:400px; overflow-y: scroll;' :placeholder="$t('Selection AM Stillnetwork')" size="large" >
              <el-option v-for="item in state.lProducteurs" :key="item.uti_n_seq" :label="`${'' + item.uti_c_prenom }`" :value="item.uti_n_seq" >
                <div style='border-top: 1px solid #e3e3e3'>
                  <div>{{ item.uti_c_prenom }}</div>
                </div>
              </el-option>
            </el-select>

            <div v-if="getLengthProd==0" class="fs-7 px-5 text-danger" style="word-break: break-word;">{{$t("Tant qu'aucun lien vers un utilisateur still n'est sélectionnée, chaque action de cet utilisateur sera envoyé sur le mail générique.")}}</div>

        </el-col>
   </el-row>

    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-secondary" @click="state.drawer = false">{{$t("Annuler")}}</el-button>
        <el-button class="btn btn-primary" @click="addEditUser">{{ modalUser.uti_n_seq > 0 ? $t("Modifier") : $t("Créer") }} {{$t("l'utilisateur")}}</el-button>
      </span>
    </template>

  </el-dialog>

  <el-dialog v-model="state.histo" :title="$t('Historique de recherche de l\'utilisateur')" width="800">
  
    <Datatable
        v-if="state.lHisto && state.lHisto.length > 0"
        :table-data="state.lHisto"
        :table-header="tableHeader"
        :key="state.reloadHisto"
        :enable-items-per-page-dropdown="true"
        :rows-per-page="50"
        :empty-table-text="$t('Aucun historique à afficher.')"
        class="table-small-td"
      >
        <template v-slot:cell-hse_d_creation="{ row: data }">{{ formatDate(data.hse_d_creation) }}</template>
        <template v-slot:cell-hse_c_search="{ row: data }">{{ data.hse_c_search }}</template>
      </Datatable>
      <div v-else class="d-flex flex-column">
        <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
        <span>{{$t("Il n'existe aucun historique de recherche sur cet utilisateur.")}}</span>
      </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-primary" @click="state.histo = false">{{$t("Fermer")}}</el-button>
      </span>
    </template>

  </el-dialog>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import mAxiosApi from "@/api";
import Users from "@/views/still/admin/users.vue"
import Histo from "@/views/still/admin/histo.vue"
import Reserv from "@/views/still/admin/reserv.vue"
import { ElNotification } from 'element-plus'
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import * as moment from "moment";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    Users,
    Histo,
    Reserv,
    Datatable
  },
 
  setup() {

    //moment.default.locale("fr");
    const { t } = useI18n()
    const state = reactive({
      loaderEnabled: true,
      lProducteurs: [] as any,
      lUsers: [] as any,
      lRoles: [] as any,
      drawer: false,
      histo: false,
      drawer_morale: false,
      count: 0,
      paneBord : 0,
      zUser: {} as any,
      reloadlst : 1,
      lHisto : [] as any, 
      reloadHisto : 1,
    });

    const tableHeader = ref([
      { name: "Date", key: "hse_d_creation", sortable: false,},
      { name: "Recherche", key: "hse_c_search", sortable: false,},
      { name: "", key: "hse_n_seq", sortable: false,},
    ]);

    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang +" HH:mm:ss");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    const modalUser= reactive({
      uti_n_seq: 0,
      uti_c_service: "",
      uti_c_phone: '',
      uti_c_mail: '',
      uti_knum_role: '',
      uti_c_prenom: '',
      uti_c_nom : '',
      uti_c_mess_instant : '',
      uti_c_status: '',
      uti_x_stillnetwork : 0,
      uti_x_adm : 0,
      uti_x_bloque: 0,
      uti_c_societe: "",
      uti_knum_user_still: 0 as any,
    });

    onMounted(async () => {
      MenuComponent.reinitialization();

      setCurrentPageBreadcrumbs("Administration de l'application", []);

      let myListLiv = await getAxios("/getSVUsers/still");
      state.lProducteurs = myListLiv.results;
      
      myListLiv = await getAxios("/getSVRoles");
      state.lRoles = myListLiv.results;

      const myListUsers = await getAxios("/getSVUsers");
      state.lUsers = myListUsers.results;

      state.lUsers.forEach(element => {
        if (element.producteur) {
          element.producteur = element.producteur.split(",").map(function(e){return parseInt(e,10)});
        }
      });
      
      state.count =state.count + 1;
      state.paneBord=1;
    });

    const newUser = () => {
      modalUser.uti_n_seq = 0;
      modalUser.uti_c_service = "",
      modalUser.uti_c_phone = '',
      modalUser.uti_c_mail = '',
      modalUser.uti_knum_role = '',
      modalUser.uti_c_prenom = '',
      modalUser.uti_c_nom = '',
      modalUser.uti_c_mess_instant = '',
      modalUser.uti_c_status = '',
      modalUser.uti_x_stillnetwork = 0,
      modalUser.uti_x_adm = 0,
      modalUser.uti_x_bloque = 0,
      modalUser.uti_knum_user_still = null,
      modalUser.uti_c_societe= "",
      state.drawer = true;
    }

    const editUser = (d) => {
      modalUser.uti_n_seq = d.uti_n_seq;
      modalUser.uti_c_service = d.uti_c_service,
      modalUser.uti_c_phone = d.uti_c_phone,
      modalUser.uti_c_mail = d.uti_c_mail,
      modalUser.uti_knum_role = d.uti_knum_role,
      modalUser.uti_c_prenom = d.uti_c_prenom,
      modalUser.uti_c_nom = d.uti_c_nom,
      modalUser.uti_c_mess_instant = d.uti_c_mess_instant,
      modalUser.uti_c_status = d.uti_c_status,
      modalUser.uti_x_stillnetwork = d.uti_x_stillnetwork,
      modalUser.uti_x_adm = d.uti_x_adm,
      modalUser.uti_x_bloque = d.uti_x_bloque,
      modalUser.uti_c_societe= d.uti_c_societe,
      modalUser.uti_knum_user_still = d.uti_knum_user_still,
      state.drawer = true;
    }

    const getLengthProd = computed(() => {
      if(!modalUser.uti_knum_user_still) return 0;
     return modalUser.uti_knum_user_still;
    });


    const affHisto = async (data) => {
      let myListHisto = await getAxios("/getSVUserHisto/" + data.uti_n_seq);
      state.lHisto = myListHisto.results;
      state.reloadHisto += 1;
      state.histo = true;
    }

    const addEditUser = () => {
      if (!modalUser.uti_c_prenom) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Prénom est obligatoire'), type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_nom) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Nom est obligatoire'), type: 'error', }); 
          return false;
        }
        if (!modalUser.uti_c_mail) {
          ElNotification({ title: t('Erreur'), message: t('Le champ Email est obligatoire'), type: 'error', }); 
          return false;
        }

        mAxiosApi
          .post("/insertEditUser", modalUser)
          .then( async() => {
            const mNotif = modalUser.uti_n_seq > 0 ? t("Utilisateur modifié avec succès !") : t("Utilisateur créé avec succès !");
            ElNotification({ title: t('Succès'), message: mNotif, type: 'success', })
            state.count = state.count + 1
            state.drawer = false;
          })

    } 

    return {
      state,
      addEditUser,
      modalUser,
      newUser,
      editUser,    
      getLengthProd,  
      affHisto,
      tableHeader,
      formatDate,
      formatDateAgo
    }
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
